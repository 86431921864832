<template>
  <v-container fluid class="pa-0">
    <v-row no-gutters>
      <v-col md="2" class="hidden-sm-and-down" />
      <v-col md="8">
        <!-- TOOLBAR -->
        <v-toolbar dense flat :max-width="1024" class="mx-auto">
          <!-- categories -->
          <v-toolbar-items>
            <v-menu offset-y bottom>
              <template v-slot:activator="{ on }">
                <v-btn plain :loading="catalogIsLoading" v-on="on">
                  {{ currentCategoryName }}
                  <v-icon right> mdi-menu-down </v-icon>
                </v-btn>
              </template>
              <v-treeview
                class="white"
                hoverable
                dense
                activatable
                open-on-click
                return-object
                :items="categoryList"
                :item-key="categoryList.id"
                @update:active="onSelectCategory"
              />
            </v-menu>
          </v-toolbar-items>
          <v-spacer />
          <!--  grid or listview -->
          <v-toolbar-items v-if="this.$vuetify.breakpoint.smAndUp">
            <v-chip v-if="catalogItemListCount" class="my-auto" small outlined>
              {{ catalogItemListCount }}</v-chip
            >
            <v-btn icon :loading="catalogIsLoading" @click.stop="toggleView">
              <v-icon v-show="catalogGridView">mdi-view-list-outline</v-icon>
              <v-icon v-show="!catalogGridView">mdi-view-grid-outline</v-icon>
            </v-btn>
          </v-toolbar-items>
          <v-spacer />
          <!-- search -->
          <v-toolbar-items>
            <v-responsive class="ml-2 my-auto">
              <v-text-field
                v-model="searchText"
                flat
                dense
                solo
                single-line
                clearable
                hide-details
                :readonly="!catalogItemListCount && !searchText"
                placeholder="Zoeken"
                append-icon="mdi-magnify"
              />
            </v-responsive>
          </v-toolbar-items>
        </v-toolbar>
      </v-col>
      <v-col md="2" class="hidden-sm-and-down" />
    </v-row>
  </v-container>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  name: "ECatalogTB",
  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      catalogItemList: "productCatalog/catalogItemList",
      catalogIsLoading: "productCatalog/catalogIsLoading",
      catalogGridView: "productCatalog/catalogGridView",
      categoryList: "productCatalog/categoryList",
      currentCategoryName: "productCatalog/currentCategoryName",
      catalogItemListCount: "productCatalog/catalogItemListCount"
    }),
    searchText: {
      get() {
        return this.$store.state.productCatalog.ui.searchText;
      },
      set(text) {
        this.$store.dispatch("productCatalog/setSearchText", text);
      }
    }
  },
  created() {
    this.$store.dispatch("productCatalog/fetchCatalog");
  },
  methods: {
    onSelectCategory(pCategory) {
      if (!pCategory[0]) {
        return;
      }
      this.$store.dispatch("productCatalog/setIsLoading", true).then(() => {
        this.$store
          .dispatch("productCatalog/setCurrentCategory", pCategory[0])
          .then(() => {
            this.$nextTick(function() {
              this.$store
                .dispatch("productCatalog/fetchCatalogItemList")
                .then(() => {
                  this.$vuetify.goTo("#scrolltarget");
                  this.$store.dispatch("productCatalog/setIsLoading", false);
                });
            });
          });
      });
    },
    toggleView() {
      this.$store.dispatch("productCatalog/setIsLoading", true).then(() => {
        this.$store.dispatch("productCatalog/toggleGridView").then(() => {
          this.$vuetify.goTo("#scrolltarget");
          this.$store.dispatch("productCatalog/setIsLoading", false);
        });
      });
    }
  }
};
</script>
